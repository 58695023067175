.studio-title {
  padding: 5px;
  position: relative;
  background: #222222;
  color: #ffffff;
  text-align: center;
  height: 45px;
}

.studio-title .proj-title {
  font-size: 18px;
  float: right;
  margin-right: 30px;
  margin-top: 12px;
}

.tituloProyecto {
  color: #222222;
  padding: 5px;
  text-align: center;
  background: #646464;
  text-shadow: 2px 2px 4px #cfcfcf;
}
/* Rol del usuario en el topBar */
.userrole h4 {
  position: relative;
  top: 20px;
  padding-top: 5px;
  padding-right: 10px;
  padding-bottom: 4px;
  padding-left: 10px;
  /*
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-transition: 8px;
  -o-border-radius: 8px;
  border-radius: 8px;

  border: 1px solid rgb(60, 63, 63);
 background-color: rgb(0, 0, 0);

  */

  color: #ffffff;
}

.descBoxScrollbar {
  height: 100px;
  margin-top: 20px;
}
.descBoxScrollbar.audio {
  margin-top: 5px;
}

.descTakeBoxScrollbar {
  height: 120px;
}

/* Botones de mute y solo de las pistas * /
.studio-layput .soundtrack .std-btn {
  display: block;
  height: 18px;
  width: 18px;
  background: #222222;
  margin: 3px;
  cursor: pointer;
  border-radius: 4px;
  -webkit-box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.75);
}

.studio-layput .soundtrack .min-btn {
  display: block;
  height: 15px;
  width: 18px;
 
  margin: 1px;
  cursor: zoom-in;
  
}

.soundtrack .min-btn.resizewave {
  background: #222222 url('/images/resizewave.png') no-repeat;
  background-size: 10px;
  background-position: center;
  margin-top: -2px;
  margin-right: -3px;
}
/* Boton mute en off * /
.soundtrack .std-btn.mute {
  background: #222222 url('/images/mute.png') no-repeat;
  background-size: 12px;
  background-position: center;
  margin-top: -4px;
  left: 62px;
}

.soundtrack .std-btn.mute:hover {
  background: #960000 url('/images/mute.png') no-repeat;
  background-size: 12px;
  background-position: center;
  margin-top: -4px;
  left: 62px;
}

.soundtrack .std-btn.mute.active {
  background: #ff0000 url('/images/mute.png') no-repeat;
  background-size: 12px;
  background-position: center;
  margin-top: -4px;
  left: 62px;
}

/* Boton record en off * /
.soundtrack .std-btn.record {
  background: #222222 url('/images/record.png') no-repeat;
  background-size: 12px;
  background-position: center;
  margin-top: -21px;
  left: 56px;
  position: absolute;
}

.soundtrack .std-btn.record:hover {
  background: #960000 url('/images/record.png') no-repeat;
  background-size: 12px;
  background-position: center;
  margin-top: -21px;
  left: 56px;
  position: absolute;
}

.soundtrack .std-btn.record.active {
  background: #222222 url('/images/recording.png') no-repeat;
  background-size: 12px;
  background-position: center;
  margin-top: -21px;
  left: 56px;
  position: absolute;
}

/* Volumen * /
.volume-slider {
  position: absolute;
  margin-top: 8px;
  margin-left: -16px;
  background-color: rgb(104, 104, 104);
}

.soundtrack .std-btn.solo {
  background: #222222 url('/images/solob.png') no-repeat;
  background-size: 12px;
  background-position: center;
  position: absolute;
  margin-top: -4px;
  left: 30px;
}

.soundtrack .std-btn.solo:hover {
  background: #c2b500 url('/images/solob.png') no-repeat;
  background-size: 12px;
  background-position: center;
  position: absolute;
  margin-top: -4px;
  left: 30px;
}

.soundtrack .std-btn.solo.active {
  background: #ffff00 url('/images/solo.png') no-repeat;
  background-size: 12px;
  background-position: center;
  position: absolute;
  margin-top: -4px;
  left: 30px;
}
*/
.creat-project .ant-form-item-label {
  display: inline-block !important;
  width: 170px;
  padding: 0px 10px !important;
}
.creat-project .ant-form-item-control-wrapper {
  display: inline-block !important;
  width: 82%;
}
.casts-list {
  margin-left: 170px !important;
}
.button-create .ant-form-item-control {
  text-align: center;
}
.lang-group {
  display: inline-block;
  padding-left: 10px;
}
.lang-group span.title {
  margin-right: 10px;
}
input.ant-input[type='file'] {
  padding: 1px;
}
.take-table {
  background: #f7f3f3;
}
.take-table th {
  background: #aac3d2 !important;
}
.list-wrapper p {
  width: 100px;
  display: inline-block;
  margin-bottom: 0px;
}
.react-mic,
.canvas-disble {
  display: none;
}

.studio-layput .content-outer-wrp {
  font-family: 'Myriad Pro', sans-serif;
  font-size: 14px;
  line-height: 1.42857;
  color: #333;
  padding: 10px;
  background: #dee7ed;
}
/* Scroll de los dialogos */
.studio-layput .content-outer-wrp .dialogues-list .scroll {
  overflow: auto;
}
.studio-layput .dialogues-cntr .dialogues-list .list-cntr {
  height: 300px;
  padding-bottom: 40px;
  padding-top: 36px;
}
.studio-layput .dialogues-cntr .dialogues-list {
  padding: 10px;
  border-radius: 5px;
  background: #646464;
}
.project-files {
  padding: 10px;
  color: #074066;
  border: 4px solid;
  border-radius: 8px;
  width: 85%;
  background: #d8d8d8;
}

/* DIALOGOS */
.transBoxScrollbar {
  background-color: rgb(252, 245, 230);
  color: #000;
  margin-top: 8px;
  margin-left: -10px;
  padding-top: 0px;
  /* height: 480px;/*auto;*/
  width: 75%;
  display: inline-block;
}
.transBoxLargeScrollbar {
  height: 285px;
}
.takeBoxScrollbar {
  height: 500px;
}
.transBoxMedScrollbar {
  height: 250px;
}
/* Scroll Dialogos */
.studio-layput .studio-content-cntr .dialogues-list .scroll {
  max-width: 100%;
  margin-left: 0px;
  display: inline;
  position: relative;
}
.studio-layput .studio-video-cntr .movie-info {
  background: #fff;
  padding: 10px 10px 10px 10px;
  border-radius: 3px;
  border-left: 5px solid #02bcd4;
  font-size: 13px;
  min-height: 150px;
}
.studio-layput .studio-video-cntr .movie-info ul li {
  margin-bottom: 5px;
}
.studio-layput .studio-video-cntr .sound-settings {
  background: #fff;
  padding: 15px 12px;
  border-radius: 3px;
  border-left: 5px solid #02bcd4;
  margin-top: 15px;
  font-size: 14px;
}
.studio-layput .studio-video-cntr .sound-settings .sound-btns a {
  padding: 7px 10px;
  display: block;
  text-align: center;
  font-size: 14px;
}
.studio-layput .studio-video-cntr .sound-settings .zoom-wrp {
  margin-top: 3px;
}

.studio-layput .studio-video-cntr .sound-settings.qa .zoom-wrp {
  margin-top: 10px;
}

.studio-layput .btn.md {
  min-width: 35px;
}
.studio-layput .btn {
  height: 30px;
  font-size: 15px;
  font-weight: normal;
  padding: 5px 15px;
  border-radius: 3px;
  line-height: 20px;
}
.studio-layput .video-player-cntr .subtitle-cntr {
  /* min-width: 300px;
  max-height: 355px;*/
  position: relative;
}
.studio-layput .video-player-cntr .subtitle-cntr.audio {
  margin-top: 115px;
}
.studio-layput .video-player-cntr .subtitle-cntr.audio-retake {
  margin-top: 70px;
}
.subtitle-cntr,
.subtitle-txt {
  border: none;
}
.subtitle-cntr {
  min-height: 250px;
}
.video-player-cntr .subtitle-cntr .subtitle-txt {
  padding: 15px 0px;
}
/* Contenedor del video */
.studio-layput .video-player-cntr .video-cntr {
  resize: both;
  overflow: auto;
  object-fit: scale-down;
}
/* Contenedor del video resizable */
.studio-layput .studio-container .video-player-cntr .video-cntr {
  height: 355px;
  width: 1000px;
}
.studio-container .video-player-cntr .video-cntr .inner {
  display: flex;
  /*  border: 1px solid red; */
  border: none;
  width: 100%;
  height: 100%;
  padding: 0;
}

/*
.trans-layout .video-player-cntr .video-cntr .inner video {
  background: #000;
  margin-bottom: 16px;
}
*/
/*.studio-layput .video-player-cntr .video-cntr */
.inner video {
  padding: 5px;
  /* border: 3px solid #adadad;;
  border-radius: 5px;
  margin-bottom: 15px;*/
  background: #000000;
  object-fit: scale-down;
  min-width: 200px;

  /*object-fit: cover;
  */
}
.studio-video-cntr .sound-settings .zoom-wrp .left-cntr li {
  border-right: none;
  margin-right: 0px !important;
  padding-right: 0px !important;
}
.studio-video-cntr .sound-settings .zoom-wrp .left-cntr li:last-child {
  border: 1px solid #c1c1c1;
}
.studio-layput .studio-video-cntr .sound-settings .zoom-wrp .left-cntr li {
  padding-right: 10px;
  margin-right: 10px;
}
.studio-layput .dialogues-cntr {
  background: #222222;
  padding: 0px 10px;
  margin-top: 0px;
  font-size: 13px;
}
.studio-layput .dialogues-cntr .dialogues-list ul li {
  padding: 2px 0;
  border: none;
}

/**********
 * PISTAS *
 **********/
.studio-layput .soundtrack {
  margin-top: 32px;
  position: relative;
  left: 2px;
}
/* Titulo de la pista /
.studio-layput .soundtrack .title h4 {
  color: rgb(212, 212, 212);
  font-size: 10px;
  font-style: bold;
  background: #222222;
  text-align: center;
  padding: 2px;
  margin-top: -3px;
  margin-left: -3px;
  margin-right: -3px;
  border-radius: 5px;
}
*/
/* Caja de controles de la pista */
.studio-layput .soundtrack .title {
  height: 44px;
  width: 115px;
  position: absolute;
  left: 0px;
  z-index: 10;
  border: 1px solid #a0a0a0;
}

.studio-layput .soundtrack .title.sizeX2 {
  height: 88px;
  width: 115px;
  position: absolute;
  left: 0px;
  z-index: 10;
}
.studio-layput .soundtrack .title.sizeX4 {
  height: 176px;
  width: 115px;
  position: absolute;
  left: 0px;
  z-index: 10;
}

.studio-layput .soundtrack .sound {
  height: 60px;
}
.studio-layput .playlist .playlist-time-scale {
  height: 30px;
}

.studio-layput
  .video-player-cntr
  .video-cntr
  .video-controls
  .center
  ul
  li
  a
  i.sprite-play,
.studio-layput
  .video-player-cntr
  .video-cntr
  .video-controls
  .center
  ul
  li
  a
  i.sprite-pause,
.studio-layput
  .video-player-cntr
  .video-cntr
  .video-controls
  .center
  ul
  li
  a
  i.sprite-stope {
  top: 4px;
}

/* Reloj en formato  00:00:00:00
.studio-layput .video-player-cntr .video-cntr .video-controls
*/
.studio-title-time {
  padding-left: 8px;
  padding-right: 8px;
  float: left;
  font-size: 28px;
  position: relative;
  background-color: rgb(0, 0, 0);
  color: rgba(0, 255, 0, 0.5);
  border: 3px solid rgb(75, 75, 75);
  border-radius: 5px;
}

.trans-layout
  .video-player-cntr
  .video-cntr
  .video-controls
  .studio-title-time {
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 1px;
  padding-bottom: 1px;

  font-size: 28px;
  top: 0px;
  background-color: rgb(28, 28, 28);
  color: #00ff00;
  border: 1px solid rgb(42, 58, 73);
  border-radius: 8px;
}

.vumetroPlay {
  padding: 4px 5px 4px 5px;
  position: absolute;
  z-index: 10;
  border: 1px solid #828282;
  background: #616161;
  left: 115px;
}

.dbfsplay {
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
    'Lucida Sans', Arial, sans-serif;
  font-size: 11px;
  color: rgb(0, 255, 0);
  background: #000000;
  border: 1px solid #828282;
  border-radius: 3px;
  position: absolute;
  padding-left: 1px;
  left: 82px;
  height: 17px;
  width: 31px;

  z-index: 11;
}

.editaPersonajes {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: flex-start;
  padding: 0px;
  height: 45px;
}

.personajesConTexto {
  width: 180px;
  height: 28px;
  background: #222222;
  color: white;
  border: none;
  border-radius: 5px;
}
.personajesSinTexto {
  width: 180px;
  height: 28px;
  margin-top: 9px;
  margin-left: 45px;
  background: #222222;
  color: white;
  border: none;
  border-radius: 5px;
}
.inputPersonaje {
  width: 280px;
  height: 28px;
  margin-top: 9px;
  margin-right: 0px;
  background: #222222;
  color: white;
  border: 1px solid #9c9c9c;
  border-radius: 5px;
}

.contVumetroRec {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: flex-start;
}
.vumetroRec {
  margin-top: 3px;
  margin-left: 5px;
  border: 1px solid #2e2e2e;
  background: #000000;
  width: 12px;
  height: 42px;
}
.dbfsrec {
  position: relative;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
    'Lucida Sans', Arial, sans-serif;
  font-size: 11px;
  color: rgb(0, 255, 0);
  background: #000000;
  border: 1px solid #828282;
  border-radius: 3px;
  top: -2px;
  left: 2px;
  height: 17px;
  width: 31px;
  z-index: 11;
}

.dbfsrecMax {
  position: relative;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
    'Lucida Sans', Arial, sans-serif;
  font-size: 11px;
  color: rgb(0, 255, 0);
  padding: 1px;
  background: #000000;
  border: 1px solid #828282;
  border-radius: 3px;
  left: 2px;
  top: 1px;
  height: 17px;
  width: 31px;
  z-index: 11;
}

.dbfsrecText {
  position: relative;
  font-family: sans-serif;
  font-size: 8.5px;
  color: rgb(32, 32, 32);
  z-index: 11;
}

/*
.video-player-cntr .video-cntr .video-controls .right {
  width: 10%;
}
*/
.playlist {
  margin: 0;
}

.playlist .playlist-time-scale {
  height: 30px;
}
.playlist .playlist-tracks {
  background: #a9a7a7;
}
.recorded-waves .playlist .playlist-tracks {
  top: -30px;
  position: relative;
}

/* Onda inactiva */
.playlist .channel {
  background: #3196da;
}
/* Onda activa */
.playlist .channel-progress {
  background: #00ffff;
}

.playlist .cursor {
  background: black;
}
.playlist .wp-fade {
  background-color: rgba(0, 0, 0, 0.1);
}
.playlist .state-cursor {
  cursor: text;
}
.playlist .state-select {
  cursor: ew-resize;
}
.playlist .state-retake {
  cursor: pointer;
}
.playlist .state-fadein {
  cursor: w-resize;
}
.playlist .state-fadeout {
  cursor: e-resize;
}
.playlist .state-shift {
  cursor: pointer;
}
.playlist .state-trimLeft {
  cursor: w-resize;
}
.playlist .state-trimRight {
  cursor: e-resize;
}
/* Cursor del playlist */
.playlist .cursor {
  background: #feb100;
}
.playlist .cursorg {
  position: absolute;
  margin: 0;
  padding: 0;
  top: 0px;

  z-index: 5;
  /* background: rgb(255, 255, 255);
 */
  width: 7px;
  height: 13px;
  border: 1px solid #feb100;
  border-radius: 0 0 4px 4px;
}
.playlist .cursorrec {
  background: #fe5000;
}
.playlist .selection.point {
  background: #0000ff;
}
.playlist .startTrack {
  background: #ff0000;
}
.playlist .endTrack {
  background: #ff0000;
}
/* Onda seleccionada */
.playlist .selection.segment {
  /*  background: #53a3ff71; */
  background: #40ff4080;
}
.playlist .channel-wrapper.silent .channel {
  opacity: 0.6;
}
.recorded-waves .channel canvas {
  border: 1px solid #221098;
}
.recorded-waves .channel-wrapper .waveform {
  border-bottom: 1px solid #b3acac;
}
.playlist .controls {
  background: white;
  text-align: center;
}
.playlist .controls header {
  overflow: hidden;
  color: white;
  background-color: rgb(43, 158, 226);
  height: 20px;
}
.playlist .controls label {
  width: 65%;
  margin: 0 0 0 5px;
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transform: translate(0, 0);
}
.playlist .controls label:before {
  content: '\f027';
  color: black;
  font-size: 14px;
  padding-right: 5px;
  -moz-osx-font-smoothing: grayscale;
}
.playlist .controls label:after {
  content: '\f028';
  color: black;
  font-size: 14px;
  padding-left: 5px;
}
.playlist .controls input[type='range'] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 45%;
  height: 10px;
  margin-top: 10px;
  display: inline-block;
}
.playlist .controls input[type='range']::-webkit-slider-runnable-track {
  height: 8px;
  background: #ddd;
  border: none;
  border-radius: 3px;
  padding: 1px;
}
.playlist .controls input[type='range']::-moz-range-track {
  height: 8px;
  background: #ddd;
  border: none;
  border-radius: 3px;
  padding: 1px;
}
.playlist .controls input[type='range']::-webkit-slider-thumb {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background: goldenrod;
  margin-top: -5px;
  cursor: ew-resize;
}
.playlist .controls input[type='range']::-moz-range-thumb {
  border: none;
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background: goldenrod;
  margin-top: -5px;
  cursor: ew-resize;
}
.playlist .controls input[type='range']:focus {
  outline: none;
}
.playlist .controls input[type='range']:focus::-webkit-slider-runnable-track {
  background: #ccc;
}
.playlist .controls input[type='range']:focus::-moz-range-track {
  background: #ccc;
}
.playlist .annotations .annotations-boxes {
  text-align: center;
}
.playlist .annotations .annotation-box {
  border: 2px dashed grey;
  padding: 0 10px;
}
.playlist .annotations .annotation-box .resize-handle {
  background: grey;
  opacity: 0.3;
  cursor: ew-resize;
}
.playlist .annotations .annotation-box .id {
  cursor: pointer;
  display: inline-block;
  width: 100%;
  height: 100%;
}
.playlist .annotations .annotations-text {
  font-size: 19px;
  font-weight: 300;
  margin-top: 1em;
  height: 160px;
  overflow-x: hidden;
  overflow-y: auto;
}
.playlist .annotations .annotations-text .annotation {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: stretch;
}
.playlist .annotations .annotations-text .annotation span {
  margin: 0.3rem 0.6rem;
}
.playlist .annotations .annotations-text .annotation span:last-of-type {
  margin-right: 1.2rem;
}
.playlist .annotations .annotations-text .annotation .annotation-id {
  font-size: 16px;
  line-height: 27px;
}
.playlist .annotations .annotations-text .annotation .annotation-start {
  font-size: 16px;
  line-height: 27px;
}
.playlist .annotations .annotations-text .annotation .annotation-end {
  font-size: 16px;
  line-height: 27px;
}
.playlist .annotations .annotations-text .annotation .annotation-lines {
  flex-grow: 10;
}
.playlist .annotations .annotations-text .annotation .annotation-actions {
  flex-basis: auto;
  width: 80px;
  text-align: right;
  font-size: 16px;
}
.playlist .annotations .annotations-text .annotation .annotation-actions i {
  margin-right: 0.6rem;
}
.playlist
  .annotations
  .annotations-text
  .annotation
  .annotation-actions
  i:last-of-type {
  margin-right: 0;
}
.playlist
  .annotations
  .annotations-text
  .annotation
  .annotation-actions
  i:hover {
  color: orange;
  cursor: pointer;
}
.playlist .annotations .current {
  background-color: #ebf4f6;
}

.playlist .vocals {
  background-color: #c0dce0;
}
.playlist .vocals header {
  background-color: green;
}
.t-c {
  text-align: center;
}

/* Style the tab */
.tab {
  overflow: hidden;
  border: 1px solid #ccc;
  background-color: #f1f1f1;
}

/* Style the buttons inside the tab */
.tab button {
  background-color: inherit;
  float: left;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 14px 16px;
  transition: 0.3s;
  font-size: 17px;
}

.carta {
  width: 200px;
  height: 20px;
  padding: 4px;
  border-radius: 5px;
  position: relative;
  color: rgb(255, 255, 255);
  background-color: #000000;
}
/* Change background color of buttons on hover */
.tab button:hover {
  background-color: #ddd;
}

/* Create an active/current tablink class */
.tab button.active {
  background-color: #ccc;
}

/*
.submit-btn {
  float: right;
  margin-right: 100px;
  margin-top: 10px;
}
*/

.export-details .export {
  padding: 10px 20px;
}
.export-details .ant-table .ant-table-tbody {
  border: 1px solid #e8e8e8;
}
.export-details .ant-table-thead > tr > th {
  padding: 7px 10px;
}
.export-details .ant-table .ant-table-tbody > tr > td {
  background: #fbfbfb;
  padding: 7px 10px;
}
.export-details .ant-table .ant-table-tbody > tr > td p {
  margin: 0px;
}
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.list-wrapper.user-info {
  display: block;
}
.list-wrapper.user-info li {
  padding: 6px 0;
}
.list-wrapper.user-info li span {
  width: 155px;
  display: inline-block;
  font-weight: bold;
  color: #484747;
}
.list-wrapper.user-info li p {
  display: block;
  width: 300px;
  position: relative;
  margin-left: 155px;
}
.list-wrapper.user-info li.prod-level p label {
  width: 100px;
}
audio::-webkit-media-controls-volume-slider {
  display: none;
}

audio::-webkit-media-controls-mute-button {
  display: none;
}
.user-profile-link,
.user-profile-link:hover {
  color: #424141;
}

/* botones del video en DIRECTOR -> TAKE y tradictor -> Translating */
.trans-layout .video-player-cntr .video-cntr .video-controls {
  background: rgb(0, 0, 0);
}

.sbtl-input {
  margin-left: 17px;
  border: 0;
  padding: 10px 20px;
  width: 80%;
  height: 50px;
  margin-top: 10px;
}
.exp-link {
  text-align: center;
  font-size: 20px;
  color: red;
}
.timeline-listing ul {
  margin: 1px !important;
  max-width: 800px;
  border: none;
}
.timeline-listing ul li {
  padding: 0px;
  margin-bottom: 10px;
}
.chrct-slct {
  width: 135px !important;
}
.chrct-slct select {
  min-width: 100px !important;
}
.comment-area {
  margin-left: 150px !important;
}
.cast-listing tr td {
  padding: 5px;
}
.create-frm .anticon-cross {
  display: none !important;
}
.ant-spin.large {
  max-height: 100% !important;
}
.studio-video-cntr .sound-settings .zoom-wrp .right-cntr li {
  margin-left: 7px;
}
.studio-layput .studio-video-cntr .sound-settings.qa .zoom-wrp .left-cntr li {
  padding-right: 8px;
  margin-right: 6px;
}
.tool-icons {
  font-size: 24px;
}
.studio-video-cntr .sound-settings .zoom-wrp .left-cntr.tool-icons {
  padding-top: 0px;
}
.studio-video-cntr .sound-settings .sound-btns ul li {
  width: 35px;
}
.studio-video-cntr .sound-settings .sound-btns ul.button li {
  min-width: 70px;
  border: none;
}
.studio-video-cntr .sound-settings .sound-btns ul.button li a {
  padding: 4px;
}
.studio-video-cntr .sound-settings .sound-btns a.active {
  background: #02bcd4;
  color: #fff;
}
.studio-video-cntr .sound-settings ul.right-cntr li {
  margin-left: 10px;
}
.video-cntr .watermark-title {
  position: absolute;
  width: 100%;
  height: 20px;
  color: #b7b2b2;
  font-size: 14px;
  padding: 5px;
  left: 16px;
  top: 12px;
}
.video-player-cntr .subtitle-cntr .btn-area-cntr.take-bttn .btn {
  margin-right: 5px;
}

#retakes .ant-table-thead > tr > th,
#retakes .ant-table-tbody > tr > td {
  padding: 8px 10px;
  color: #2a9c87;
}
#retakes .ant-table-thead > tr > th,
#retakes .ant-table-tbody > tr > td a p {
  margin-bottom: 2px;
  color: #2a9c87;
}
/*#characters .ant-table-thead > tr > th, */
#characters .ant-table-tbody > tr > td a p {
  margin-bottom: 2px;
  color: #2a3b9c;
}
/*
#retakes .ant-table-body .submit-btn{
  margin: 10px 50px 10px 0px;
}
*/
#takes .ant-table-thead > tr > th,
#takes .ant-table-tbody > tr > td {
  padding: 8px 10px;
  color: #2a9c87;
}
#takes .ant-table-thead > tr > th,
#takes .ant-table-tbody > tr > td a p {
  margin-bottom: 2px;
  color: #2a9c87;
}
.project-main {
  height: 185px;
  width: 100%;
  display: block;
}
.project-main img {
  max-width: 150px;
  max-height: 150px;
  display: block;
  float: left;
  margin-right: 10px;
}
.project-main h2,
.project-main p {
  display: block;
}
.presentation {
  margin-left: 237px;
  margin-right: 10px;
  margin-bottom: 20px;
}
.m-r-25 {
  margin-right: 25px;
}
.rcrd-error {
  margin-left: 325px;
  color: red;
}
.pointer {
  cursor: pointer;
}
.m-l-250 {
  margin-left: 250px;
}
.m-l-315 {
  margin-left: 315px;
}
.n-d {
  width: 100px !important;
  margin-left: 30px !important;
}
.w-40 {
  width: 40% !important;
  margin-left: 5px !important;
}
.w-46 {
  width: 46px; /* !important; */
}
.w-20 {
  width: 20% !important;
  margin-left: 10px !important;
}
.ddline-cast {
  width: 95%;
  text-align: center;
  border-bottom: 1px solid #dedede;
  line-height: 0.1em;
  margin: 20px 0 20px -10px;
}
.ddline-cast span {
  background: #f4f4f4;
  padding: 0 0px;
  font-size: 13px;
}
.date-list {
  margin-left: 40px;
  position: relative;
  width: 210px;
}
.date-list .notify {
  top: -15px;
  position: absolute;
  font-size: 13px;
  right: 52px;
}
.date-list .prj-ttl {
  top: -15px;
  position: absolute;
  font-size: 13px;
  left: 30px;
}
.m-t-5 {
  margin-top: 5px !important;
}
.chrct-drpdown {
  visibility: hidden;
  position: absolute;
}
.chrct-drpdown.active {
  visibility: visible;
  position: absolute;
  max-height: 85px;
  background-color: #fff;
  border: #eee 1px solid;
  width: 100%;
  margin: 0;
  padding: 0;
  overflow: auto;
}
.chrct-drpdown.active > li {
  padding: 5px 15px;
  border-bottom: #eee 1px solid;
}
.lst-characters {
  height: 30px;
  width: 100%;
  padding-left: 5px;
  position: relative;
}
.chrct-list-block {
  position: relative;
}
.create-project .ant-form .inputFileFX label:before,
.create-project .ant-form label.ant-checkbox-wrapper:before {
  content: '';
}
.b-g-w {
  background-color: white;
  text-align: left;
}
.org-take {
  visibility: hidden;
}
.table tr:hover .org-take {
  visibility: visible;
}
.cast-note {
  left: -20px;
  width: 210px !important;
  margin-top: 10px !important;
  /* margin-left: 50px !important; */
  resize: none !important;
}
.disabled {
  pointer-events: none;
}

.studio-container {
  background: #222222;
  position: relative;
  left: 0;
  top: 0;
  margin: 0 0 0 0;
}

/*
.studio-container
  .video-player-cntr
  .video-cntr
  .video-controls
  .sprite-record {
  background: url('/images/studio/player/voice-recorder.svg') no-repeat 4px 3px;
  background-size: 22px;
}

.studio-container
  .video-player-cntr
  .video-cntr
  .video-controls
  .sprite-record.active {
  background: url('/images/studio/player/voice-recorder-active.svg') no-repeat
    4px 3px;
  background-size: 22px;
}
*/

.studio-container .studio-video-cntr .movie-info {
  border: none;
  background: #212623;
  min-height: 20px;
  font-size: 14px;
}
.studio-container .studio-video-cntr .movie-info h3 {
  color: #ffffff;
  height: 20px;
  width: 84%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 20px;
}
.studio-container .studio-video-cntr .movie-info .tk {
  height: 40px;
  padding: 10px 0px !important;
}

/*
.studio-container .studio-video-cntr .movie-info .submit-btn,
*/
.studio-container .audiobook .submit-btn,
.studio-container .studio-video-cntr .movie-info .approve-btn,
.studio-container .audiobook .approve-btn {
  position: absolute;
  z-index: 10;
  height: 35px;
  width: 35px;
  background: #2a6e34 url('/images/studio/upload.svg') no-repeat 5px 5px;
  background-size: 25px;
  right: 10px;
  top: 8px;
  margin: 0px;
  border-radius: 5px;
  -webkit-box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.75);
}

.studio-container .studio-video-cntr .movie-info .approve-btn {
  background: #ffffff url('/images/studio/approve.svg') no-repeat 0px 0px;
  background-size: 35px;
  border-radius: 7px;
}
.studio-container .audiobook .approve-btn {
  background: #ffffff url('/images/studio/approve.svg') no-repeat 0px 0px;
  background-size: 35px;
  border-radius: 7px;
}
.studio-container .audiobook .submit-btn,
.studio-container .audiobook .approve-btn {
  top: 12px;
}
.studio-container .studio-video-cntr .movie-info div {
  color: #999999;
}
.studio-container .studio-video-cntr .movie-info .clearfix {
  display: flex;
  padding: 0 0 10px 0;
}
.studio-container .studio-video-cntr .movie-info .clearfix div {
  margin-right: 10px;
}
/* Carta creativa
.studio-container .studio-video-cntr .movie-info
 */
.project-desc {
  height: 300px;
  width: 25%;
  background-color: rgb(252, 245, 230);
  color: #000;

  padding: 10px;
  border-radius: 5px;
  border: 3px solid rgb(128, 128, 128);
  float: right;
  margin-top: -300px;
  margin-right: 10px;
}
/* OTROS PERSONAJES * /
.studio-container .dialogues-cntr .dialogues-list .list-cntr .less-visible {
  opacity: 0.3;
}
/* PERSONAJES EN DIALOGOS /
.studio-container .dialogues-cntr .dialogues-list .list-cntr p span {
  background: #323232;
  color: white;
  padding: 5px 10px;
  margin: 2px;
  border-radius: 5px;
  box-shadow: 3px 3px 8px 0px rgba(0, 0, 0, 0.75);
}

.studio-container .dialogues-cntr .dialogues-list .list-cntr p .text-comment {
  color: #f7f100;
  font-style: italic;
  opacity: 0.5;
  font-size: 15px;
}
*/
.studio-container .dialogues-cntr,
.studio-container .dialogues-cntr .dialogues-list {
  background: #212623;
  color: #f7f100;
}
/* Dialogos */
.studio-container .dialogues-cntr .dialogues-list .scroll {
  /*  width: 100%;*/
  font-size: 18px;
  display: inline;
}

/* Contenedor de los botones del video
.studio-container */
.studio-botones-container {
  background: #646464;
  width: 740px;
  margin-left: 2px;
  border-radius: 5px;
  margin-top: 0px;
}
.studio-container .studio-botones-container {
  display: flex;
  justify-content: space-around;
}
.studio-botones-container div {
  display: flex;
  align-content: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
}
.studio-botones-container div.bar {
  width: 1px;
  height: 48px;
  background: #222222;
}

/* Botones del video */
.studio-botones-container .std-btno {
  display: block;
  height: 33px;
  width: 33px;
  background: #303030;
  margin: 7px;
  cursor: pointer;
  border-radius: 5px;
  -webkit-box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.75);
}

/* Boton para ocultar dialogos * /
.studio-botones-container .std-btn.menos {
  background: #222222 url('/images/book-solid.png') no-repeat;
  background-size: 18px;
  background-position: center;
}
/* Boton para mostrar dialogos * /
.studio-botones-container .std-btn.mas {
  background: #222222 url('/images/book-reader-solid.png') no-repeat;
  background-size: 18px;
  background-position: center;
}

/* Boton grabar REC
.studio-botones-container .std-btn.rec {
  background: #222222 url('/images/studio/player/voice-recorder.svg') no-repeat;
  background-size: 18px;
  background-position: center;
}

/* Boton grabar REC /
.studio-botones-container .std-btn.newrec {
  background:  url('/images/studio/player/new-record.svg')
    no-repeat;
  background-size: 18px;
  background-color: #222222;
  background-position: center;
}

/* Boton grabando RECORDING /
.studio-botones-container .std-btn.stoprec {
  background: #222222 url('/images/studio/player/voice-recorder-active.svg')
    no-repeat;
  background-size: 18px;
  background-position: center;
}
/* Boton grabando RECORDING en preroll /
.studio-botones-container .std-btn.prerollrec {
  background: #222222 url('/images/studio/player/voice-recorder-preroll.png')
    no-repeat;
  background-size: 18px;
  background-position: center;
}

/* Boton take anterior * /
.studio-botones-container .std-btn.take-anterior {
  background: #222222 url('/images/previous.png') no-repeat;
  background-size: 16px;
  background-position: center;
}
/* Boton retrocede 6 seg * /
.studio-botones-container .std-btn.retrocede6s {
  background: #222222 url('/images/backward.png') no-repeat;
  background-size: 26px;
  background-position: center;
}
/* Boton foto atras * /
.studio-botones-container .std-btn.foto-atras {
  background: #222222 url('/images/foto-atras.png') no-repeat;
  background-size: 23px;
  background-position: center;
}
/* Boton play * /
.studio-botones-container .std-btn.play {
  background: #222222 url('/images/play-solid.png') no-repeat;
  background-size: 14px;
  background-position: center;
}
/* Boton pausa * /
.studio-botones-container .std-btn.pausa {
  background: #222222 url('/images/pause-solid.png') no-repeat;
  background-size: 14px;
  background-position: center;
}
/* Boton stop * /
.studio-botones-container .std-btn.stop {
  background: #222222 url('/images/stop-solid.png') no-repeat;
  background-size: 14px;
  background-position: center;
}
/* Boton foto alante * /
.studio-botones-container .std-btn.foto-alante {
  background: #222222 url('/images/foto-alante.png') no-repeat;
  background-size: 23px;
  background-position: center;
}
/* Boton avanza 6 seg * /
.studio-botones-container .std-btn.avanza6s {
  background: #222222 url('/images/forward.png') no-repeat;
  background-size: 26px;
  background-position: center;
}
/* Boton take siguiente * /
.studio-botones-container .std-btn.take-siguiente {
  background: #222222 url('/images/next.png') no-repeat;
  background-size: 16px;
  background-position: center;
}
/* Boton añadir personaje */
.studio-botones-container .std-btno.addChar {
  background: #222222 url('/images/user-plus-solid.png') no-repeat;
  background-size: 20px;
  background-position: center;
}
/* Boton quitar personaje */
.studio-botones-container .std-btno.delChar {
  background: #222222 url('/images/user-minus-solid.png') no-repeat;
  background-size: 20px;
  background-position: center;
}

/* Contenedor de herramientas de edición de pistas
.studio-container .studio-tools-container {
  background: #646464;
  width: 602px;
  margin-top: 1px;
  margin-left: 140px;
  border-radius: 5px;
}
*/
/* Contenedor de botones para edición de pistas* /
.studio-container .studio-tools-container {
  background: #646464;
  border-radius: 5px;
  margin-top: 1px;
  width: 602px;
  margin-left: 140px;
}

/* Contenedor de la información del take /
.studio-container .studio-take-info {
  background: #000000;
  width: 137px;
  height: 89px;
  margin-left: 2px;
  margin-bottom: -10px;
  padding: 4px;
  border: 4px solid #646464;
  border-radius: 5px;
  float: left;
}
.studio-container .studio-take-info .etiqueta {
  font-size: 12px;
  color: white;
  text-align: right;
  padding-left: 3px;
  padding-right: 5px;
  width: 56px;
  float: left;
}
.studio-container .studio-take-info .valor {
  font-size: 12px;
  color: rgb(0, 255, 0);
  text-align: left;
}
*/

.studio-container .studio-tools-container {
  display: flex;
  justify-content: space-around;
}
.studio-tools-container div {
  display: flex;
  align-content: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
}
.studio-tools-container div.bar {
  width: 1px;
  height: 48px;
  background: #222222;
}

/* Botones de edicion de pistas
.studio-tools-container
 */
.std-btn {
  display: block;
  height: 33px;
  width: 33px;
  background: #414743;
  margin: 7px;
  cursor: pointer;
  border-radius: 5px;
  -webkit-box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.75);
}

.studio-tools-container .approve-btn {
  background: #222222 url('/images/aprobar.png') no-repeat 0px 0px;
  background-size: 21px;
  background-position: center;
}

/* Flechita de selección de pista
.studio-tools-container
*/
.std-btn.onda {
  background: #222222 url('/images/onda.png') no-repeat 9px 9px;
  background-size: 64px;
  background-position: center;
}

/* Flechita de selección de pista
.studio-tools-container
*/
.std-btn.select {
  background: #222222 url('/images/studio/pointer.svg') no-repeat 15px 8px;
  background-size: 12px;
  background-position: center;
}
/* Manita de desplazar pista */
.studio-tools-container .std-btn.hand {
  background: #222222 url('/images/studio/hand.svg') no-repeat 11px 10px;
  background-size: 20px;
  background-position: center;
}
.studio-tools-container .std-btn.zoomin {
  background: #222222 url('/images/studio/zoom-in.svg') no-repeat 9px 9px;
  background-size: 18px;
  background-position: center;
}
.studio-tools-container .std-btn.zoomout {
  background: #222222 url('/images/studio/zoom-out.svg') no-repeat 9px 9px;
  background-size: 18px;
  background-position: center;
}

.studio-tools-container .std-btn.area {
  background: #222222 url('/images/studio/select.svg') no-repeat 8px 8px;
  background-size: 18px;
  background-position: center;
}
.studio-tools-container .std-btn.trim {
  background: #222222 url('/images/studio/trim.svg') no-repeat 9px 9px;
  background-size: 18px;
  background-position: center;
}
.studio-tools-container .std-btn.move {
  background: #222222 url('/images/studio/cutter.svg') no-repeat 9px 9px;
  background-size: 18px;
  background-position: center;
}

.studio-tools-container .std-btn.trash {
  /*  background: #8d403f url("/images/studio/trash.svg") no-repeat 9px 9px; */
  background: #222222 url('/images/studio/trash.svg') no-repeat 9px 9px;
  background-size: 18px;
  background-position: center;
}
.std-btn.trashred {
  /*  background: #8d403f url("/images/studio/trash.svg") no-repeat 9px 9px; */
  background: #222222 url('/images/trash-alt-solid.png') no-repeat 9px 9px;
  background-size: 18px;
  background-position: center;
}
.std-btn.plusgreen {
  /*  background: #8d403f url("/images/studio/trash.svg") no-repeat 9px 9px; */
  background: #222222 url('/images/plus-solid.png') no-repeat 9px 9px;
  background-size: 18px;
  background-position: center;
}
.studio-tools-container .std-btn.undo {
  /*  background: #006563 url("/images/studio/undo.svg") no-repeat 9px 9px; */
  background: #222222 url('/images/undo.png') no-repeat 9px 9px;
  background-size: 18px;
  background-position: center;
}
/* .studio-tools-container*/
.std-btn.save {
  /*  background: #2a6e34 url("/images/studio/floppy.svg") no-repeat 10px 10px; */
  background: #222222 url('/images/floppy.png') no-repeat 10px 10px;
  background-size: 18px;
  background-position: center;
}

.std-btn.exportCSV {
  /*  background: #2a6e34 url("/images/studio/floppy.svg") no-repeat 10px 10px; */
  background: #222222 url('/images/exportCSV.png') no-repeat 10px 10px;
  background-size: 30px;
  background-position: center;
}

/* .studio-tools-container */
.std-btn.submit-btn {
  /*  background: #2a6e34 url("/images/studio/upload.svg") no-repeat 10px 10px;  */
  background: #222222 url('/images/upload.png') no-repeat 10px 10px;
  background-size: 22px;
  background-position: center;
}

.studio-tools-container.qa-tools .std-btn {
  height: 33px;
  width: 33px;
}

.studio-tools-container.qa-tools .std-btn.select {
  background: #222222 url('/images/studio/pointer.svg') no-repeat 12px 7px;
  background-size: 12px;
  background-position: center;
}
.studio-tools-container.qa-tools .std-btn.hand {
  background: #222222 url('/images/studio/hand.svg') no-repeat 9px 8px;
  background-size: 20px;
  background-position: center;
}
.studio-tools-container.qa-tools .std-btn.zoomin {
  background: #222222 url('/images/studio/zoom-in.svg') no-repeat 8px 8px;
  background-size: 18px;
  background-position: center;
}
.studio-tools-container.qa-tools .std-btn.zoomout {
  background: #222222 url('/images/studio/zoom-out.svg') no-repeat 8px 8px;
  background-size: 18px;
  background-position: center;
}

.studio-tools-container.qa-tools .std-btn.area {
  background: #222222 url('/images/studio/select.svg') no-repeat 6px 6px;
  background-size: 18px;
  background-position: center;
}
.studio-tools-container.qa-tools .std-btn.trim {
  background: #222222 url('/images/studio/trim.svg') no-repeat 8px 8px;
  background-size: 18px;
  background-position: center;
}
.studio-tools-container.qa-tools .std-btn.move {
  background: #222222 url('/images/studio/cutter.svg') no-repeat 8px 8px;
  background-size: 18px;
  background-position: center;
}
.studio-tools-container.qa-tools .std-btn.trash {
  background: #222222 url('/images/studio/trash.svg') no-repeat 7px 8px;
  background-size: 18px;
  background-position: center;
}
.studio-tools-container.qa-tools .std-btn.undo {
  /* background: #222222 url("/images/studio/undo.svg") no-repeat 8px 7px; */
  background: #222222 url('/images/undo.png') no-repeat 8px 7px;
  background-size: 18px;
  background-position: center;
}
.studio-tools-container.qa-tools .std-btn.save {
  /*  background: #222222 url("/images/studio/floppy.svg") no-repeat 8px 8px; */
  background: #222222 url('/images/floppy.png') no-repeat 8px 8px;
  background-size: 18px;
  background-position: center;
}
.studio-tools-container.qa-tools .std-btn.air {
  background: #222222 url('/images/studio/onair.svg') no-repeat 8px 8px;
  background-size: 18px;
  background-position: center;
}
.studio-tools-container.qa-tools .std-btn.air.locked {
  background: #222222 url('/images/studio/onair.svg') no-repeat 8px 8px;
  background-size: 18px;
  background-position: center;
}
.studio-tools-container.qa-tools .std-btn.retake {
  background: #222222 url('/images/studio/retake.svg') no-repeat 6px 6px;
  background-size: 18px;
  background-position: center;
}

/* Boton seleccionado */
.studio-tools-container .std-btn.active {
  background-color: #006891 !important;
}
.studio-tools-container .std-btn.disabled {
  opacity: 0.3;
}

/****************
/* Onda grande  *
*****************/
.nuevaonda {
  display: flex;
  justify-content: left;
  position: relative;
  z-index: 12;
}
.ondadiv {
  width: 100%;

  border: 1px solid rgb(255, 255, 255);
  margin-right: 8px;

  background: rgb(200, 200, 200);
  height: auto;
  overflow-x: auto;
  overflow-y: hidden;
  display: flex;
  justify-content: left;
  padding: 0;
  position: relative;
  z-index: 10;
}
.ondacanvas {
  position: relative;

  z-index: 10;
}
.ondacursor {
  position: absolute;
  z-index: 13;

  /*  height: 64px; */
  width: 1px;
  background-color: rgb(255, 187, 0);
}

/* Contenedor de los botones de la onda*/
.onda-botones-container {
  background: #646464;
  width: 138px;
  /* height: 128px; */
  /* margin-left: 2px; */
  border-radius: 5px;
  padding: 4px;
  margin-top: 0px;
  margin-right: 1px;
  position: relative;
}
.solapabotones {
  top: 0px;
  margin-left: 18px;
  width: 112px;
  border-radius: 5px;
  height: 18px;
  background: blue;
  color: white;
  text-align: center;
  cursor: 'ns-resize';
}

.ondaclose {
  background: url('/images/studio/delete.svg') no-repeat 0px 0px;
  background-size: 18px;
  position: absolute;
  left: 2px;
  top: 3px;
  width: 20px;
  height: 20px;
}

.botonesonda {
  display: flex;
  justify-content: space-around;
}

/*
.onda-botones-container div {
  display: flex;
  align-content: center;
  justify-content: space-evenly;
  flex-wrap: wrap;

}

.onda-botones-container div.bar {
  width: 1px;
  height: 48px;
  background: #222222;
}
*/
/* Botones de la onda */
.onda-botones-container .boton {
  display: block;
  height: 16px;
  width: 16px;
  background: #303030;
  margin: 7px;
  cursor: pointer;
  border-radius: 5px;
  -webkit-box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.75);
}
/* Boton zoom horizontal menos */
.onda-botones-container .boton.zhmenos {
  background: #222222 url('/images/caret-left-solid.png') no-repeat;
  background-size: 8px;
  background-position: center;
}
/* Boton zoom horizontal mas */
.onda-botones-container .boton.zhmas {
  background: #222222 url('/images/caret-right-solid.png') no-repeat;
  background-size: 8px;
  background-position: center;
}
/* Boton zoom vertical menos */
.onda-botones-container .boton.zvmenos {
  background: #222222 url('/images/caret-down-solid.png') no-repeat;
  background-size: 12px;
  background-position: center;
}
/* Boton zoom vertical mas */
.onda-botones-container .boton.zvmas {
  background: #222222 url('/images/caret-up-solid.png') no-repeat;
  background-size: 12px;
  background-position: center;
}

/* Dialogos */

.studio-container .transBoxScrollbar {
  height: 300px;
  border-radius: 5px;
  border: 4px solid rgb(128, 128, 128);
  color: black;
  float: left;
}
/*.audiobook .studio-container .transBoxScrollbar {
  height: 300px;
}
*/
.studio-container .soundtrack {
  top: -20px;
}

/* Números en la regla */
.studio-container .playlist .playlist-time-scale {
  /*  color: #9c9c9c; */
  color: #d4cdb6;
}

/* Fondo de las pistas */
.studio-container .playlist .playlist-tracks {
  background: #c4c2a7;
  border: 1px solid #ce9e03;
  scrollbar-color: rgb(123, 123, 122) rgba(0, 0, 0, 1);
  scrollbar-width: 140px;
  margin-right: 8px;
}

/* Controles de pistas */
.studio-container .soundtrack .title {
  background: #646464;
  padding: 4px;
}

.studio-container .soundtrack .title label {
  width: 74%;
  /*display: inline-block;*/
  float: right;
  line-height: 15px;
  margin: 0;
}
.studio-container .soundtrack .title label input[type='range'] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: inline-block;
  width: 71%;
  height: 0px;
  vertical-align: middle;
}
.studio-container
  .soundtrack
  .title
  input[type='range']::-webkit-slider-runnable-track {
  height: 4px;
  background: #ddd;
  border: none;
  border-radius: 3px;
  padding: 1px;
  left: 10px;
}

/* Barrita del volumen */
.studio-container .soundtrack .title input[type='range']::-moz-range-track {
  height: 3px;
  width: 80px;
  background: rgb(88, 0, 0);
  border: 1px solid rgb(146, 146, 146);
  border-radius: 2px;
  padding: 0px;
}

.studio-container .soundtrack .title input[type='range']::-webkit-slider-thumb {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  height: 13px;
  width: 13px;
  border-radius: 50%;
  background: goldenrod;
  margin-top: -5px;
  cursor: ew-resize;
}

/* Bolita del volumen */
.studio-container .soundtrack .title input[type='range']::-moz-range-thumb {
  border: none;
  height: 10px;
  width: 6px;
  border: 2px solid rgb(196, 196, 196);
  border-radius: 32%;
  background: rgba(0, 0, 126, 0.7);
  margin-top: 0px;
  cursor: ew-resize;
}
.studio-container .soundtrack .title input[type='range']:focus {
  outline: none;
}
.studio-container
  .soundtrack
  .title
  input[type='range']:focus::-webkit-slider-runnable-track {
  background: #ccc;
}
.studio-container
  .soundtrack
  .title
  input[type='range']:focus::-moz-range-track {
  background: #ccc;
}

.studio-container .soundtrack .title label::before {
  content: '-';
  color: rgb(230, 179, 40);
  font-size: 16px;
  position: absolute;
  margin-left: -30px;
  margin-top: 1px;
  /*
  padding: 0 2px 0 2px;
  -moz-osx-font-smoothing: grayscale;
*/
}
.studio-container .soundtrack .title label::after {
  content: '+';
  color: rgb(230, 179, 40);
  font-size: 12px;
  position: absolute;
  margin-left: 74px;
  margin-top: 1px;
  /*
  padding-left: 2px;
  -moz-osx-font-smoothing: grayscale;
*/
}
.studio-container .dialogues-cntr {
  margin-top: 0px;
  padding: 0px 10px;
}
.studio-container .dialogues-cntr .dialogues-list {
  padding: 0 10px;
}
/*
.studio-container .playlist .playlist-tracks .sprite-close {
  background: url('/images/studio/delete.svg') no-repeat 0px 0px;
  background-size: 16px;
  position: absolute;
  left: 0px;
  top: 0px;
}
.studio-container .retake-studio .playlist .playlist-tracks .sprite-close {
  display: none;
}
*/

.playlist-tracks::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
/* Track */
.playlist-tracks::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-border-radius: 10px;
  border-radius: 10px;
}
/* Handle */
.playlist-tracks::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: rgb(123, 123, 122);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}
.playlist-tracks::-webkit-scrollbar-thumb:window-inactive {
  background: rgb(123, 123, 122);
}
.studio-container .audiobook {
  padding: 10px;
}
.studio-container .audiobook h3 {
  color: #fff;
}
.studio-container .audiobook span {
  height: 80px;
  display: inline-block;
  color: #ddd;
}
.actor-list {
  width: 52%;
}
.actor-list .ant-table-thead > tr > th {
  font-size: 11px;
  padding: 12px 8px;
}
.actor-list .ant-table-tbody > tr > td {
  font-size: 11px;
}
.actor-list .ant-table-tbody > tr > td img {
  width: 20px;
  height: 20px;
}
.casting-list {
  width: 47%;
}
.casting-list .cast-actor {
  /* background: #35aaef; */
  display: flex;
  justify-content: flex-start;
}
.casting-list .cast-actor .avatar {
  display: block;
  width: 55px;
  height: 100%;
  background: #4a8bce;
}
.casting-list .cast-actor .avatar span {
  background: url('/images/studio/user.svg') no-repeat;
  background-size: 30px;
  width: 33px;
  height: 30px;
  display: block;
  margin-top: 10px;
}
.casting-list .cast-actor .char-title {
  color: #ffffff;
  margin: 18px 0px 0px -10px;
  font-size: 20px;
}
.casting-list .cast-actor .two {
  padding-top: 15px;
  width: 100%;
}
.casting-list .cast-actor .two .va {
  margin-bottom: 5px;
}
.casting-list .cast-actor .one textarea {
  background: #369cf5;
  border: none;
  margin-left: 10px;
  color: #ffffff;
}
.casting-list .cast-actor .two .cast-slct {
  background: #3e7fc1;
  border: none;
  color: #ffffff;
  width: 95% !important;
}
ul.selected-user li .casting-tag {
  background: #9a9a9a;
  text-align: left;
  padding: 0px 10px;
  height: 30px;
  line-height: 30px;
  width: 95%;
  text-overflow: ellipsis;
  white-space: nowrap;
}

ul.selected-user li .casting-tag.accepted,
ul.selected-user li .casting-tag.cast-color {
  background: #55ec00;
}

ul.selected-user li .casting-tag.cast-queue {
  /*  background: #9a9a9a; */
  background: #ffa000;
}

ul.selected-user li .casting-tag.cast-reject {
  /*  background: #f4b100; */
  background: #ff0000;
}

ul.selected-user .casting-tag .close {
  height: 19px;
  width: 19px;

  background: #64646480;
  /* opacity: 0.8;
*/
  color: rgb(255, 255, 255);
  padding: 0px 0px 0px 6px;
  border-radius: 50%;
  /*border: 3px solid rgb(117, 115, 115);
  line-height: 21px; */
  font-size: 13px;
  top: 3px;
  right: 3px;
}
.ddline-cast span {
  background: #35aaef;
}
.casting-list .cast-actor .one .ddline-cast {
  text-align: left;
  color: #ffffff;
  text-transform: uppercase;
}
.casting-list .cast-actor .one .date-list {
  margin-left: -10px;
}
.casting-list .cast-actor .one .date-list .ant-calendar-picker {
  width: 75% !important;
}
.casting-list .cast-actor .one .date-list .w-40 {
  width: 45px !important;
  padding: 5px 0px;
  background: #369cf5;
  border: none;
  color: #d4edf3;
}
.casting-list .cast-actor .one .date-list .t-c {
  text-align: left;
}
.casting-list .cast-actor .one .date-list .ant-calendar-picker-input {
  background: #369cf5;
  border: none;
  color: #d4edf3;
}
.casting-list .cast-actor .one .date-list .ant-calendar-picker-icon:after {
  color: #d4edf3;
}
.ant-upload-list-item .anticon-cross {
  opacity: 1 !important;
  font-size: 15px !important;
}
.cast-actor .assign-new-btn {
  margin: 5px 10px;
  opacity: 1;
  padding: 5px 24px;
  line-height: 0px;
  height: 28px;
}
.cast-actor::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

.cast-actor::-webkit-scrollbar {
  width: 6px;
  background-color: #f5f5f5;
}

.cast-actor::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #7b8492;
}
.help-icon {
  background: url('/images/help.svg') no-repeat 5px 5px;
  background-size: 30px;
  width: 40px;
  height: 40px;
  display: inline-block;
  position: absolute;
  right: 0px;
}
.actr-lst {
  background: #ffffff;
  border: none;
  padding: 10px 0;
}
.actr-lst label {
  font-weight: normal;
  margin: 0 0 0 5px;
  font-size: 12px;
}
.actr-lst .ant-slider {
  margin: 4px 6px 10px;
}
.actr-lst .ant-slider-track {
  background-color: #509ac7;
}
.actr-lst .ant-slider-handle {
  border: solid 2px #509ac7;
}
.actr-lst .ant-slider-mark-text {
  font-size: 12px;
}
.actor-list tr:hover {
  background: #ffffff;
}
.actor-list .row {
  margin: 0px;
  padding: 5px;
  border: 1px solid #ddd;
}
.actor-list .usa {
  background: url('/images/studio/volume.svg') no-repeat 0px 0px;
  background-size: 24px;
  width: 24px;
  height: 24px;
  display: block;
}
.actor-list .gender.male {
  background: url('/images/studio/male.svg') no-repeat 0px 0px;
  background-size: 14px;
  width: 14px;
  height: 14px;
  display: block;
}
.actor-list .gender.female {
  background: url('/images/studio/female.svg') no-repeat 0px 0px;
  background-size: 19px;
  width: 18px;
  height: 20px;
  display: block;
}
.actor-list tr:nth-child(even) {
  background-color: #ffffff;
}
.actor-list td,
.actor-list th {
  border: none;
}
.contact-list {
  padding: 0px !important;
}
.mrgn-right-5 {
  margin-right: 5px;
}
.studio-tools-container .tool-section2 {
  max-width: 315px;
}
.studio-tools-container.qa-tools .tool-section2 {
  max-width: 280px;
}
.ant-slider-mark span:nth-child(1) {
  width: 50% !important;
  margin-left: -3px !important;
  text-align: left;
}
.ant-slider-mark span:nth-child(2) {
  width: 50% !important;
  margin-left: -48% !important;
  text-align: right;
}

.app-lang span img,
.app-lang-option.ant-select-dropdown-menu-item span img {
  width: 20px;
  height: 20px;
  margin-right: 6px;
}
.banderita {
  width: 40px;
  height: 30px;
  margin-right: 3px;
  padding: 3px 3px 3px 3px;
  border: 1px solid #646464;
  border-radius: 5px;
  background: #222222;
}

.idioma {
  height: 30px;
  width: 130px;
  color: white;
  background: #222222;
  border: 1px solid #646464;
  border-radius: 5px;
  margin-top: 21px;
}

.isomorphicSidebar .isoLogoWrapper h3 a,
.isoLoginContent .isoLogoWrapper a {
  text-transform: none !important;
}
.project-details-box .list-wrapper label {
  width: 110px;
}
.project-details-box .list-wrapper .change-user {
  opacity: 0;
  width: 20px;
}
.project-details-box .list-wrapper .opt:hover {
  background: #e4eef582;
}
.project-details-box .list-wrapper li:hover .change-user {
  opacity: 1;
  cursor: pointer;
}
.rem-m-bottom .ant-tabs-bar {
  margin-bottom: 0;
}

.set-tab-width .ant-tabs-nav {
  width: 100%;
}

.set-tab-plane .ant-tabs-tab {
  width: 50%;
  margin-right: 0;
}

.rem-scroll .ant-tabs-nav-scroll {
  overflow: visible;
  white-space: nowrap;
}

.icon-erase:before {
  font-family: 'fontAwesome';
  content: '\f014';
  font-size: 20px;
}
textarea {
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
}
.file-supports {
  font-size: 10px;
  font-weight: normal;
  height: 20px;
  position: absolute;
  top: 24px;
  margin: 0px;
  padding: 0px;
  color: #074066;
}
.ant-select-disabled .ant-select-selection {
  background-color: #f5f5f5 !important;
  cursor: not-allowed;
  color: black;
}
.spinner {
  width: 70px;
  margin-top: 10px;
  float: right;
}

.spinner > div {
  width: 10px;
  height: 10px;
  background-color: #333;

  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.spinner .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}
.isoChatBoxWrapper .isoChatArea .isoSingleContact .chatTime {
  display: inline-block;
  position: absolute;
  top: 52px;
  font-size: 10px;
}
.takes-list .table .table-scrolling .char-title {
  text-transform: capitalize;
}

.isoLoginContentWrapper .pub.app-lang.ant-select {
  position: absolute;
  top: 10px;
  right: 10px;
}

.isoLoginContentWrapper .isoLoginContent.reset-pwd {
  padding: 70px 10px;
}
.ant-calendar-picker .ant-input.ant-input-disabled {
  background: #f5f5f5;
  cursor: not-allowed;
}
.ant-select.ant-select-disabled
  .ant-select-selection.ant-select-selection--single,
.ant-form .ant-row .ant-form-item-control .ant-input.ant-input-disabled {
  cursor: not-allowed !important;
}

/* Slider del video */
.studio-container .video-cntr .range-cntr input[type='range'],
.translation .video-cntr .range-cntr input[type='range'],
.taking .video-cntr .range-cntr input[type='range'] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: inline-block;
  height: 0px;
}
.studio-container .video-player-cntr .video-cntr .video-controls {
  background: #646464;
  border: 1px solid rgb(185, 185, 185);
  border-radius: 5px;
  margin-left: 1px;
  width: flex - 20px;
  height: 20px;
}
.studio-container .video-cntr .range-cntr input[type='range']::-moz-range-track,
.translation .video-cntr .range-cntr input[type='range']::-moz-range-track,
.taking .video-cntr .range-cntr input[type='range']::-moz-range-track {
  height: 5px;
  background: rgb(88, 0, 0);
  border: 1px solid rgb(146, 146, 146);
  width: 99%;
  top: 4px;
}

.studio-container .video-cntr .range-cntr input[type='range']::-moz-range-thumb,
.translation .video-cntr .range-cntr input[type='range']::-moz-range-thumb,
.taking .video-cntr .range-cntr input[type='range']::-moz-range-thumb {
  border: none;
  height: 13px;
  width: 10px;
  border-radius: 5px;
  border: 2px solid rgb(255, 255, 255);
  background: rgb(0, 52, 131);
  cursor: ew-resize;
}
/*  Hasta aqui el slider del video */

/* Slider de la onda */
.progress {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: inline-block;
  height: 0px;
  background: #646464;
  border: 1px solid rgb(185, 185, 185);
  border-radius: 5px;
  margin-left: 1px;
  width: flex - 20px;
  height: 20px;
}
.progress .range-cntr input[type='range']::-moz-range-track {
  height: 5px;
  background: rgb(211, 18, 18);
  border: 1px solid rgb(146, 146, 146);
  width: 99%;
  top: 4px;
}
.range-cntr input[type='range']::-moz-range-thumb {
  border: none;
  height: 13px;
  width: 10px;
  border-radius: 5px;
  border: 2px solid rgb(255, 255, 255);
  background: rgb(0, 52, 131);
  cursor: ew-resize;
}
/*  Hasta aqui el slider de la onda */

.rmv-link {
  cursor: pointer;
  display: inline-block;
  position: absolute;
  text-decoration: underline;
  margin-left: 10px;
}
.project-details-box .notific-msg {
  font-size: 14px;
}
.ant-modal-content .ant-modal-body .retake-tech label > span {
  width: 85px;
  font-size: 13px;
  display: inline-block;
}
.tech-retake-tb table {
  width: 100%;
  border: 1px solid #e4dfdf;
}
.tech-retake-tb {
  margin-top: 10px;
}
.tech-retake-tb table tr th,
.tech-retake-tb table tr td {
  padding: 3px;
  border-bottom: 1px solid #e4dfdf;
}
.tech-retake-tb table tr td a {
  color: #f64744;
  font-size: 16px;
}
.isoChatBoxWrapper .isoFooter.ant-layout-footer {
  background: #e9f2fb;
}
.isoFooter .isoIconWrapper {
  width: 100%;
  text-align: center;
}
.isoFooter .isoIconWrapper .record-btn {
  width: 100px;
  height: auto;
  display: inline-block;
}
@-webkit-keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
  }
}

@keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
.player,
.player .video-js {
  width: 100%;
}
.video-js .vjs-big-play-button {
  display: none !important;
}
.video-js.vjs-watermark {
  display: block;
}
.video-js .vjs-watermark-content {
  color: white;
  opacity: 0.5;
  position: absolute;
  padding: 15px;
  -webkit-transition: transition(visibility 1s, opacity 1s);
  -moz-transition: transition(visibility 1s, opacity 1s);
  -ms-transition: transition(visibility 1s, opacity 1s);
  -o-transition: transition(visibility 1s, opacity 1s);
  transition: transition(visibility 1s, opacity 1s);
}
.video-js .vjs-watermark-top-right {
  right: 0;
  top: 0;
}
.video-js .vjs-watermark-top-left {
  left: 0;
  top: 0;
}
.video-js .vjs-watermark-bottom-right {
  right: 0;
  bottom: 30px;
}
.video-js .vjs-watermark-bottom-left {
  left: 0;
  bottom: 30px;
}
.video-js.vjs-user-inactive.vjs-playing .vjs-watermark-fade {
  opacity: 0;
}
